// src/utils/indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'FormDataDB';
const DB_VERSION = 1;
const STORE_NAME = 'formData';

export async function initializeDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
}

export async function saveData(data) {
  const db = await initializeDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  await tx.store.add(data);
  await tx.done;
}

export async function getData() {
  const db = await initializeDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const allData = await tx.store.getAll();
  await tx.done;
  return allData;
}
