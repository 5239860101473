// src/components/StepperForm.js
import React, { useEffect, useState } from 'react';
import { Button, Stepper, Step, StepLabel, TextField, Box, Grid, InputLabel } from '@mui/material';
import { encryptData, decryptData } from '../utils/crypto';
import { saveData, getData } from '../utils/indexedDB';
import axios from 'axios';
import NetworkStatus from './NetworkStatus';
import Loader from './loader';
const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Image Uploads'];

const StepperForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    step1: Array(20).fill(''),
    step2: Array(20).fill(''),
    step3: Array(20).fill(''),
    step4: Array(20).fill(''),
    step5: Array(20).fill(''),
    images: Array(20).fill(null),
  });
  const [retrievedData, setRetrievedData] = useState(null);
  const [isSyncLoading, setSyncIsLoading] = useState(false);
  const [isPageLoading, setPageIsLoading] = useState(false);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleChange = (step, index, value) => {
    const updatedStepData = [...formData[step]];
    updatedStepData[index] = value;
    setFormData({ ...formData, [step]: updatedStepData });
  };

  const getGeolocation = async () => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; // Replace with your Google API key

    try {
      const response = await axios.post(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`
      );
      const { lat, lng } = response.data.location;
      console.log('Latitude:', lat, 'Longitude:', lng);
      return { lat, lng };
    } catch (error) {
      console.error('Error using Google Geolocation API:', error);
    }
  };

  const handleImageChange = async (index, file) => {
    if (!file) return;

    const reader = new FileReader();

    reader.onloadend = async () => {
      // Get the user's geolocation
      const location = await getGeolocation();

      const updatedImages = [...formData.images];
      const imageData = {
        dataUrl: reader.result, // Base64 image data
        dateTime: new Date().toLocaleString(), // Capture current date and time
        location: location, // Store the user's location (latitude, longitude)
      };

      updatedImages[index] = imageData;
      setFormData({ ...formData, images: updatedImages });
    };

    reader.onerror = (error) => {
      console.error('FileReader error:', error);
      alert('Error reading file: ' + error.message);
    };

    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    try {
      const encryptedData = encryptData(formData);
      await saveData({ encryptedData });
      console.log('Data saved to IndexedDB!');
      setActiveStep(steps.length); // Go to the final step
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleRetrieve = async () => {
    try {
      const allData = await getData();
      if (allData.length > 0) {
        // Decrypt the latest entry
        const latestEntry = allData[allData.length - 1];
        const decryptedData = decryptData(latestEntry.encryptedData);
        // setRetrievedData(decryptedData);
        console.log('Retrieved data:', decryptedData);
        setFormData(decryptedData)
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
    }
  };

  const handleFillFakeData = () => {
    const fakeData = generateFakeData();
    setFormData(fakeData);
  };

  const generateFakeData = () => {
    const fakeData = { ...formData };
    for (let i = 1; i <= 5; i++) {
      fakeData[`step${i}`] = [];
      for (let j = 1; j <= 20; j++) {
        fakeData[`step${i}`].push(Math.floor(Math.random() * 100));
      }
    }
    return fakeData;
  };

  const getSavedData = async () => {
    try {
      setPageIsLoading(true);
      const endpoint = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/requests`;
      const response = await axios.get(
        endpoint
      );
      console.log('response: ', response);

      if (response.status === 200) {
        const responseData = response.data?.slice(-1)[0] || {};
        console.log('responseData: ', responseData);
        let data = { ...formData };
        Object.keys(data).forEach((item, itemIndex) => {
          data[item] = [];
          for (let j = 1; j <= 20; j++) {
            if (item.includes('step')) {
              data[item].push(responseData.input[`input_${(itemIndex * 20) + (j)}`] || "");
            } else if (item.includes('images')) {
              data[item].push({
                "dataUrl": responseData.photos[`photo_${j}`]?.file || "",
                "dateTime": responseData.photos[`photo_${j}`]?.time || "",
                "location": {
                  "lat": responseData.photos[`photo_${j}`]?.lat || "",
                  "lng": responseData.photos[`photo_${j}`]?.long || "",
                },
              });
            }
          }
        });
        console.log('data: ', data);
        setFormData(data);
      }
      setPageIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setPageIsLoading(false);
    }
  };

  const handleSyncData = async () => {
    try {
      setSyncIsLoading(true)
      let payload = {
        "input": Object.keys(formData).reduce((prev, current, i) => {
          if (current.includes('step')) {
            return {
              ...prev, ...formData[current].reduce((prev, current, inputIndex) => {
                return {
                  ...prev,
                  [`input_${(i * 20) + (inputIndex + 1)}`]: current
                }
              }, {})
            }
          } else return prev;
        }, {}),
        "photos": formData.images.reduce((prev, current, imageIndex) => {
          return {
            ...prev,
            [`photo_${(imageIndex + 1)}`]: {
              "file": current?.['dataUrl'] || "",
              "lat": current?.['location']?.['lat'] || "",
              "long": current?.['location']?.['lng'] || "",
              "time": current?.['dateTime'] || ""
            },
          }
        }, {})
      }
      console.log('payload: ', payload);
      const endpoint = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/request/store`;
      const response = await axios.post(
        endpoint,
        payload
      );
      setSyncIsLoading(false)
    } catch (error) {
      console.error('Error:', error);
      setSyncIsLoading(false)
    }
  };

  const getStepContent = (step) => {
    if (step < 5) {
      // Steps 1 to 5: Random text inputs
      const stepKey = `step${step + 1}`;
      return (
        <Box className="p-4">
          <Grid container spacing={2}>
            {formData[stepKey].map((value, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <TextField
                  label={`Input ${(step * 20) + (index + 1)}`}
                  fullWidth
                  value={value}
                  onChange={(e) => handleChange(stepKey, index, e.target.value)}
                  margin="normal"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    } else {
      // Step 6: Image uploads
      return (
        <Box className="p-4">
          <Grid container spacing={2}>
            {formData.images?.map((image, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <InputLabel>{`Image ${index + 1}`}</InputLabel>
                <input
                  type="file"
                  accept="image/*"
                  capture="environment" // Opens camera on supported devices
                  onChange={(e) => handleImageChange(index, e.target.files[0])}
                  style={{ margin: '8px 0' }}
                />
                {image && <>
                  <img src={image.dataUrl} alt={`Upload ${index + 1}`} style={{ width: '100%', height: 'auto', marginTop: '8px' }} />
                  <p>Latitude: {image?.location?.lat}</p>
                  <p>Longitude: {image?.location?.lng}</p>
                  <p>Time: {image?.dateTime}</p>
                </>}
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }
  };

  useEffect(() => {
    getSavedData();
  }, []);

  return (
    <>
      {!isPageLoading ? (<div className="flex flex-col items-center justify-center">
        <NetworkStatus handleSyncDataCallback={handleSyncData} isLoading={isSyncLoading} />
        <div className="max-w-lg mx-auto mt-4 p-4 shadow-lg rounded-lg bg-white">
          <Stepper activeStep={activeStep} alternativeLabel className='sticky-header'>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div className="text-center">
                <h2 className="text-lg font-bold">All steps completed</h2>
                <Button variant="contained" color="primary" onClick={() => setActiveStep(0)} disableRipple>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                {getStepContent(activeStep)}
                <Box className="p-4 flex justify-between sticky-buttons">
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Next
                    </Button>
                  )}
                </Box>
              </div>
            )}
          </div>
          <div className="text-center mt-4">
            <div className='flex justify-center items-center gap-4'>
              <Button variant="contained" color="secondary" onClick={handleRetrieve} disableRipple>
                Retrieve From Local
              </Button>
              <Button variant="contained" color="secondary" onClick={handleFillFakeData} disableRipple>
                Auto Fill Data
              </Button>
            </div>
            {retrievedData && (
              <div className="mt-4">
                <h3>Retrieved Data:</h3>
                {steps.slice(0, 5).map((step, stepIndex) => (
                  <div key={stepIndex}>
                    <h4>{step} Data:</h4>
                    {retrievedData[`step${stepIndex + 1}`].map((value, index) => (
                      <p key={index}><strong>{`Input ${index + 1}:`}</strong> {value}</p>
                    ))}
                  </div>
                ))}
                <h4>Image Uploads:</h4>
                <Grid container spacing={2}>
                  {retrievedData.images.map((image, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <img src={image?.dataUrl} alt={`Retrieved Image ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                      <p>Latitude: {image?.location?.lat}</p>
                      <p>Longitude: {image?.location?.lng}</p>
                      <p>Time: {image?.dateTime}</p>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </div>
        </div>
      </div>) : (
        <div className="flex flex-col items-center justify-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default StepperForm;
