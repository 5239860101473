import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Loader from './loader';

const NetworkStatus = ({
  handleSyncDataCallback,
  isLoading
}) => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    // Handler for offline event
    const handleOffline = () => {
      setIsOffline(true);
    };

    // Handler for online event
    const handleOnline = () => {
      setIsOffline(false);
    };

    // Attach event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Function to handle data synchronization
  const handleSyncData = () => {
    // Implement your data sync logic here
    console.log('Syncing data with server...');
    handleSyncDataCallback();
  };

  return (
    <div className='w-full'>
      {isOffline ? (
        <div className="offline-message">
          <p>You are currently offline. Some features may not be available.</p>
        </div>
      ) : (
        <div className="sync-prompt flex justify-end items-end mr-2 mt-4">  
          {!isLoading ? (<Button variant="contained" color="primary" onClick={handleSyncData} disableRipple>
            Sync Data
          </Button>) : (
            <Loader size={20} isWrapper={true} />
          )}
        </div>
      )}
    </div>
  );
};

export default NetworkStatus;
