import React, { useEffect } from 'react';
import StepperForm from './components/StepperForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const App = () => {
  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // Disable ripple globally
        },
      },
    },
  });
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <ThemeProvider theme={theme}>
        <StepperForm />
      </ThemeProvider>
    </div>
  );
};

export default App;
